<template>
    <div class="position-relative z-1">
        <nav id="navbarProfil" class="navbar navbar-expand-md navbar-light">
            <div style="background-color: white" class="d-lg-none pt-2 pl-4">
                <a class="navbar-brand" href="#">{{ $t('profil.navbar') }}</a>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navigationProfil"
                    aria-controls="navigationProfil"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon" />
                </button>
            </div>

            <div id="navigationProfil" class="collapse navbar-collapse">
                <div class="card-deck w-100 navbar-nav mr-auto">
                    <NavigationCard
                        id="tab1"
                        image="/assets/img/zebre-hotline.svg"
                        :label="$t('profil.tab.personnal-data')"
                        :is-selected="defaultPanel === 'profil.tab.profil'"
                        @onSelect="setPanel('profil.tab.profil')"
                    />

                    <NavigationCard
                        id="tab2"
                        image="/assets/img/zebre-lingot.svg"
                        :label="$t('profil.tab.address')"
                        :is-selected="defaultPanel === 'profil.tab.address'"
                        @onSelect="setPanel('profil.tab.address')"
                    />

                    <NavigationCard
                        id="tab4"
                        image="/assets/img/parrainage.svg"
                        :label="$t('profil.tab.sponsorship')"
                        :is-selected="defaultPanel === 'profil.tab.sponsorship'"
                        @onSelect="setPanel('profil.tab.sponsorship')"
                    />
                </div>
            </div>
        </nav>

        <slide-x-left-transition :duration="600">
            <div v-if="defaultPanel === 'profil.tab.profil'" :key="'profil.tab.profil'" class="container profil">
                <AccountProfile :user-profile="profile" @save="save" />
                <AccountPasswordChange :user-profile="profile" @submitUpdatePassword="submitUpdatePassword" />
                <AccountDelete :user-profile="profile" @deleteAccount="deleteAccount" />
            </div>

            <div v-if="defaultPanel === 'profil.tab.address'" :key="'profil.tab.address'" class="container profil">
                <AccountAddress :user-profile="profile" />
                <AccountOrderMail :user-profile="profile" @save="save" />
            </div>

            <div
                v-if="defaultPanel === 'profil.tab.sponsorship'"
                :key="'profil.tab.sponsorship'"
                class="container profil"
            >
                <AccountSponsorship :user-profile="profile" @save="save" />
            </div>
        </slide-x-left-transition>

        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    import AccountProfile from '@/components/Profil/AccountProfile.vue'
    import AccountPasswordChange from '@/components/Profil/AccountPasswordChange.vue'
    import NavigationCard from '@/components/Profil/NavigationCard.vue'
    import AccountAddress from '@/components/Profil/AccountAddress.vue'
    import AccountSponsorship from '@/components/Profil/AccountSponsorship.vue'

    import Toaster from '@/components/UI/Toaster'
    import AccountDelete from '@/components/Profil/AccountDelete.vue'
    import AccountOrderMail from '@/components/Profil/AccountOrderMail.vue'

    export default {
        title: 'layout.profil',
        components: {
            AccountOrderMail,
            AccountDelete,
            AccountProfile,
            AccountPasswordChange,
            NavigationCard,
            AccountAddress,
            AccountSponsorship,
            Toaster
        },
        data: () => ({
            langues: [
                { text: 'Français', id: 'fr' },
                { text: 'Anglais', id: 'en' }
            ],
            defaultPanel: 'profil.tab.profil',
            confirmDelete: null,
            confirmPassword: null,
            newPassword: null,
            profile: {},
            tab: 0,
            messages: [],
            alertType: null,
            attestation: null,
            savingAttestation: false
        }),
        /*watch: {
            user(user) {
                this.profile = user
            }
        },*/
        created() {
            this.getUser().then(() => {
                this.profile = this.user
            })
        },
        computed: {
            ...mapState('auth', {
                user: state => state.user
            }),
            typesParrainage() {
                return [
                    { text: this.$t('profil.sponsorship.credit'), id: 'credit' },
                    { text: this.$t('profil.sponsorship.earn'), id: 'gain' }
                ]
            },
            totalFilleulsAmount() {
                let total = 0
                if (this.profile && this.profile.filleuls) {
                    this.profile.filleuls.forEach(f => {
                        total += f.montant
                    })
                }
                return total
            },
            alertClass() {
                if (this.alertType === 'error') {
                    return 'alert-danger'
                }
                return 'alert-success'
            }
        },
        methods: {
            ...mapActions('auth', {
                getUser: 'me',
                updateUser: 'update',
                updatePassword: 'updatePassword',
                deleteUser: 'delete'
            }),
            save(data) {
                this.updateUser(this.profile)
                    .then(data => {
                        this.fireToast('success', data.message, '')
                    })
                    .catch(response => {
                        let msg = []
                        if (response.data.errors) {
                            for (const [, value] of Object.entries(response.data.errors)) {
                                value.forEach(txt => msg.push(txt))
                            }
                        }

                        this.fireToast('error', msg, '')
                    })
                    .finally(() => {
                        if (data.callback) {
                            data.callback()
                        }
                    })
            },
            submitUpdatePassword(data) {
                this.updatePassword({
                    password: data.password,
                    password_confirmation: data.password_confirmation
                })
                    .then(data => {
                        this.fireToast('success', data.message, '')
                    })
                    .catch(response => {
                        let msg = []
                        if (response.data.errors) {
                            for (const [, value] of Object.entries(response.data.errors)) {
                                value.forEach(txt => msg.push(txt))
                            }
                        }

                        this.fireToast('error', msg, '')
                    })
                    .finally(() => {
                        if (data.callback) {
                            data.callback()
                        }
                    })
            },
            deleteAccount() {
                this.deleteUser()
                    .then(() => {
                        window.location.href = '/suppression-compte-confirmation.php'
                    })
                    .catch(error => {
                        if (error.data && error.data.message) {
                            this.fireToast('error', error.data.message, '')
                        } else {
                            let msg = []
                            if (error.data.errors) {
                                for (const [, value] of Object.entries(error.data.errors)) {
                                    value.forEach(txt => msg.push(txt))
                                }
                            }

                            this.fireToast('error', msg.join('<br>'), '')
                        }
                    })
            },
            fireSuccess: function (message) {
                this.fireToast('success', message, '')
            },
            fireError: function (message) {
                this.fireToast('error', message, '')
            },
            fireToast: function (type, message, message1) {
                this.$refs.toaster.fire(type, message, message1)
            },
            setPanel: function (panel) {
                this.defaultPanel = panel
            }
        }
    }
</script>

<style lang="scss" scoped>
    .deleteSection {
        margin-top: 20vh;

        h2 {
            color: #dc3545;
        }
    }

    .row {
        margin-bottom: 2rem;

        .col {
        }
    }

    .url {
        display: flex;

        .sponsorshipLink {
            width: 360px;
            background-color: #b7b7b7;
            border: 1px solid #b7b7b7;
            margin-right: 10px;

            &:focus {
                outline: none;
            }

            &::selection {
                background-color: transparent;
            }
        }
    }

    #navbarProfil {
        display: block;
        background-color: transparent;
    }
</style>
